<template>
    <div id="rules">
        <div class="banner" :style="{backgroundImage: `url('${campfire}')`}"></div>
        <div class="container">
            <div class="page">
                <div class="start">
                    <h1>The rules</h1>
                    <p>The rules are what keep us civilised. Respect the rules and you won't get banned.</p>
                </div>
                <div class="the-rules">
                    <div class="rule" v-for="(rule, r) in rules" :key="r">
                        <h2>{{ r + 1 }}. {{ rule.title }}</h2>
                        <div class="description" v-html="rule.description"></div>
                    </div>
                </div>
                <div class="end">
                    <p>
                        <br>
                        <br>
                    </p>
                    <p>
                        These are the rules! Honor them, admire them, love them, cuddle them... idk.
                        <br>
                        As long you don't break or bend the rules, its fine by me.
                        <br>
                        <br>
                        That's all, folks!
                        <br>
                        <br>
                        ~
                        <b>Rob_Varestorm</b>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Campfire from "@/assets/images/night_campfire.jpeg"

export default {
    name: "Rules",
    data: () => ({
        rules: [],
        campfire: Campfire
    }),
    async created() {
        await this.$axios.post("collections/get/rules").then(this.onRules).catch(this.noRules)
    },
    methods: {
        onRules(result) {
            this.rules = result?.data?.entries ?? []
        },
        noRules(result) {
            console.error(result)
            this.rules = []
        }
    }
}
</script>
<style lang="sass" scoped>
@import "src/assets/style/views/Rules"
</style>

